import { dispatch } from 'app/store';
import DismissNotification from './DismissNotification';
type Args = {
  message: string;
  noticeId?: string; // for avoiding duplicates if we like
  bad?: boolean;
  timeoutMs?: number;
};
export default function NotifyUser(args: Args) {
  const { message, timeoutMs, bad } = args;
  const id = args.noticeId || `${Date.now()}-${message}`;
  dispatch({
    type: 'NOTIFY_USER',
    message,
    bad: bad || false,
    id,
  });
  if (timeoutMs != null) {
    setTimeout(() => {
      DismissNotification(id);
    }, timeoutMs);
  }
}
