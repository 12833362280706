import { Action, PurgeNotificationAction } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
export type Notifications = {
  ids: Array<string>;
  byId: {
    [x: string]: Notification;
  };
};
type Notification = {
  id: string;
  message: string;
  bad: boolean;
  // should it look ominous?
  dismissed: boolean;
}; // make it disappear
const defaultState = {
  ids: [],
  byId: {},
};
export default function notificationsReducer(
  state: ReducerInputState<Notifications> = defaultState,
  action: Action,
): Notifications {
  switch (action.type) {
    case 'NOTIFY_USER':
      return Object.assign({}, state, {
        ids: [...new Set(state.ids!.concat([action.id]))],
        byId: Object.assign({}, state.byId, {
          [action.id]: {
            id: action.id,
            message: action.message,
            bad: action.bad,
            dismissed: false,
          },
        }),
      });
    case 'DISMISS_NOTIFICATION':
      return Object.assign({}, state, {
        byId: Object.assign({}, state.byId, {
          [action.id]: Object.assign({}, state.byId[action.id], {
            dismissed: true,
          }),
        }),
      });
    case 'PURGE_NOTIFICATION':
      return Object.assign({}, state, {
        ids: state.ids.filter((id) => {
          return (
            id !==
            // this type assertion is here because flow loses track of
            // the action's type from the case statement, inside of callbacks
            (action as any as PurgeNotificationAction).id
          );
        }),
        byId: Object.assign({}, state.byId, {
          [action.id]: undefined,
        }),
      });
    default:
      return state;
  }
}
